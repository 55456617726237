@import "../../variables";

.header-component {
  position: relative;
  z-index: 9;

  nav {
    background: #fff;
    box-shadow: $shadow-big-2;
    border-radius: 16px;
    padding: 0 32px;
    margin-top: 20px;
    height: 92px;
  }

  .navbar-brand {
    padding: 0;
    display: block;
  }

  .menu-items {
    margin-left: auto;
  }

  .links {
    display: flex;
    align-items: center;
    border-right: 1px solid $gray-1;
    height: 32px;
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
      border-right: none;
    }
  }

  .main-nav .navbar-nav {
    .link-nav {
      font-size: 14px;
      line-height: 24px;
      color: $color-text;
      margin: 0 16px;
      padding: 0;
      text-decoration: none;
      position: relative;

      &:focus {
        color: $color-text;
      }

      &.active {
        /*&::after {
          content: '';
          position: absolute;
          bottom: -8px;
          left: 0;
          right: 0;
          width: 100%;
          height: 2px;
          background-color: $color-milluu-orange;
        }*/
      }
    }
  }

  .link-app {
    margin-right: 16px;
  }

  .dropdown-language-btn {
    background: transparent;
    border: none;
    border-radius: 0;
    padding: 0;
    display: flex;
    align-items: center;

    &:focus,
    &:hover {
      outline: none;
      box-shadow: none;
      background: transparent;
    }

    &::after {
      border: none;
      background: url("./img/icon-expand-more.svg");
      width: 14px;
      height: 8px;
      background-repeat: no-repeat;
      background-size: contain;
      vertical-align: middle;
      margin-left: 13px;
    }
  }

  .flag-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    position: relative;
    border: 2px solid #333;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
    }
  }

  .dropdown-menu[data-bs-popper] {
    margin-top: 7px;
    padding: 0;
    width: 54px;
    left: -10px;
    min-width: auto;
    border-color: $gray-1;

    .dropdown-item {
      padding: 5px 10px;

      &:hover {
        background-color: transparent;
      }

      &.selected {
        background-color: $gray-2;
      }
    }
  }
}

@media (min-width: 1400px) {
  .header-component {
    > .container {
      max-width: 1230px;
    }
  }
}

@media (max-width: 991px) {
  .header-component {
    nav {
      margin-top: 4px;
      height: 48px;
      border-radius: 8px;
      filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.2));
      box-shadow: none;
      padding: 0 16px;
    }

    .navbar-brand {
      width: 89px;
    }

    .navbar-toggler {
      border: none;
      padding: 0;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &.collapsed {
        .navbar-toggler-icon {
          background-image: url('./img/icon-menu.svg');
          width: 23px;
          height: 12px;
        }
      }

      .navbar-toggler-icon {
        background-image: url('./img/icon-close.svg');
        width: 23px;
        height: 16px;
        background-size: contain;
        background-position: center;
      }
    }

    .navbar-collapse {
      width: 100%;
      background-color: #fff;
      position: absolute;
      top: 40px;
      padding-top: 8px;
      left: 0;
      right: 0;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    .links:first-of-type {
      flex-direction: column;
    }

    .links {
      justify-content: center;
      border-right: none;
      margin-right: 0;
      padding-top: 16px;
      padding-bottom: 16px;
      border-top: 1px solid $gray-1;
      height: auto;
    }

    .main-nav .navbar-nav {
      .link-nav {
        margin-bottom: 32px;
        margin-left: 0;
        margin-right: 0;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .link-app {
      margin-right: 24px;

      &:last-child {
        margin-right: 0;
      }
    }

    .dropdown-menu[data-bs-popper] {
      position: absolute;
    }
  }
}