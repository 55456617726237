@import "../../variables";

.home-component {
  .wrapper-hero-description {
    h1 {
      width: 710px;
      margin-left: 32px;
      margin-right: 32px;
    }
  }

  .section-cta {
    padding-top: 80px;
    padding-bottom: 40px;

    .row-cta {
      margin-left: -20px;
      margin-right: -20px;
    }

    .col-cta {
      width: 50%;
      padding-left: 20px;
      padding-right: 20px;
    }

    .box-wrapper {
      height: 100%;
      padding: 0;
      display: flex;
      flex-direction: column;
    }

    .img {
      position: relative;
      height: 0;
      padding-bottom: 47.5%;

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin: auto;
      }
    }

    .description {
      padding: 24px;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .section-title {
      margin-bottom: 16px;
    }

    p {
      margin-bottom: 16px;
    }

    .main-button {
      margin-top: auto;
    }
  }

  .section-news {
    padding-bottom: 160px;

    .col-news {
      max-width: 536px;
      color: $color-text;

      img {
        width: 94px;
        height: 94px;
        //filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.1));
      }

      p {
        flex: 1;
        padding-left: 24px;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
}

@media (max-width: 991px) {
  .home-component {
    .section-cta {
      .row-cta {
        margin-left: -12px;
        margin-right: -12px;
      }

      .col-cta {
        padding-left: 12px;
        padding-right: 12px;
      }
    }

    .section-news {
      padding-bottom: 80px;

      .col-news {
        max-width: 100%;
      }
    }
  }
}

@media (max-width: 767px) {
  .home-component {
    .wrapper-hero-description {
      h1 {
        width: 253px;
        margin-left: 0;
        margin-right: 0;
      }

      img {
        margin-top: -55px;

        &:first-of-type {
          margin-right: -40px;
        }

        &:nth-of-type(2) {
          margin-left: -40px;
        }
      }
    }

    .section-cta {
      padding-top: 24px;
      padding-bottom: 0;

      .col-cta {
        width: 100%;
        margin-bottom: 24px;
      }

      .section-title {
        text-align: center;
      }

      .main-button {
        width: 100%;
      }
    }

    .section-news {
      padding-bottom: 60px;

      .col-news {
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .home-component {
    .section-news {
      .col-news {
        flex-direction: column;

        img {
          margin-top: 24px;
          order: 2;
        }

        p {
          padding-left: 0;
          order: 1;
        }
      }
    }
  }
}