@import "variables";

@font-face {
  font-family: 'Montserrat';
  src: url('resources/fonts/Montserrat-SemiBold.woff2') format('woff2'),
  url('resources/fonts/Montserrat-SemiBold.woff') format('woff'),
  url('resources/fonts/Montserrat-SemiBold.ttf') format('ttf'),
  url('resources/fonts/Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('resources/fonts/Montserrat-Regular.woff2') format('woff2'),
  url('resources/fonts/Montserrat-Regular.woff') format('woff'),
  url('resources/fonts/Montserrat-Regular.ttf') format('ttf'),
  url('resources/fonts/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('resources/fonts/Montserrat-Bold.woff2') format('woff2'),
  url('resources/fonts/Montserrat-Bold.woff') format('woff'),
  url('resources/fonts/Montserrat-Bold.ttf') format('ttf'),
  url('resources/fonts/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

:root {
  --bs-gutter-x: 15px;
}

html {
  scroll-behavior: auto !important;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: $color-text;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: 400;
}

p {
  margin: 0;
}

a {
  text-decoration: navajowhite;
}

strong {
  font-weight: 700;
}

img {
  max-width: 100%;
}

.orange-text {
  color: $color-milluu-orange;
}

.green-text {
  color: $color-milluu-green;
}

.blue-text {
  color: $color-milluu-blue;
}

.red-text {
  color: $color-milluu-red;
}

.violet-text {
  color: $color-milluu-violet;
}

.yellow-text {
  color: $color-milluu-yellow;
}

.border-color-green {
  border-color: $color-milluu-green;
}

.border-color-orange {
  border-color: $color-milluu-orange;
}

.border-color-red {
  border-color: $color-milluu-red;
}

.border-color-yellow {
  border-color: $color-milluu-yellow;
}

.border-color-blue {
  border-color: $color-milluu-blue;
}

.border-color-violet {
  border-color: $color-milluu-violet;
}

.section-title {
  font-size: 24px;
  line-height: 32px;
}

.obs, small {
  font-size: 11px;
  line-height: 14px;
}

.wrapper-section-under-hero {
  margin-top: -167px;
  position: relative;
  z-index: 5;
}

.unstyled-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.unstyled-button {
  padding: 0;
  background: transparent;
  border-radius: 0;
  border: none;
  box-shadow: none;
  outline: none;
}

.cursor-pointer {
  cursor: pointer;
}

.box-wrapper {
  background: #fff;
  box-shadow: $shadow-big-1;
  border-radius: 16px;
  padding: 24px;
  overflow: hidden;
}

.custom-modal {
  padding: 20px;

  .modal-dialog {
    max-width: 720px;
    margin: 0 auto;
  }

  .modal-content {
    display: block;
    border: none;
    border-radius: 16px;
    padding: 24px;
    box-shadow: $shadow-big-2;
    position: relative;
    overflow: auto;
  }

  .header-modal {
    padding: 0 0 16px;
    border: none;
  }

  .section-title {
    flex: 1;
  }

  h3 {
    margin-bottom: 24px;
  }

  .small-header {
    font-size: 18px;
    line-height: 24px;
  }

  p {
    font-size: 14px;
    line-height: 24px;
  }

  .obs {
    font-size: 11px;
    line-height: 14px;
    margin-top: 4px;
  }

  .btn-close {
    padding: 0;
    margin: 0;
    width: 32px;
    height: 32px;
    opacity: 1;
    background: url("resources/img/icon-close.svg");
    -webkit-background-size: contain;
    background-size: contain;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.slick-arrow {
  &::before {
    content: '';
    width: 13px;
    height: 20px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    font-family: initial;
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.slick-prev {
  left: -50px;

  &::before {
    background-image: url('resources/img/icon-prev.svg');
  }
}

.slick-next {
  right: -50px;

  &::before {
    background-image: url('resources/img/icon-next.svg');
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .container {
    max-width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .container {
    max-width: 768px; //768 + padding
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1166px; //1136 + padding
  }
}

@media (max-width: 1200px) {
  .slick-prev {
    left: 0;
  }

  .slick-next {
    right: 0;
  }

  .slick-slider {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (max-width: 991px) {
  .wrapper-section-under-hero {
    margin-top: -45px;
  }
}