@import "../../variables";

.main-button {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  padding: 16px;
  color: #fff;
  background-color: $color-milluu-blue;
  border-radius: 16px;
  text-align: center;

  &.with-shadow {
    box-shadow: $shadow-big-2;
  }

  &.white-button {
    background-color: #fff;
    color: $color-milluu-blue;

    &:hover {
      color: $color-milluu-blue;
    }
  }
  
  &:hover {
    color: #fff;
  }

  &.orange {
    background-color: $color-milluu-orange;
  }

  &.green {
    background-color: $color-milluu-green;
  }
}