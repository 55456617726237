@import "../../variables";

.hero {
  background-size: cover;
  background-position: center;
  height: 960px;
  margin-top: -112px;
  padding-top: 112px;
  position: relative;

  .bg-image {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }

  .bg-gradient {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    mix-blend-mode: multiply;
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0) 50%, rgba(#fff, 0) 100%);
    z-index: 2;
  }

  .description {
    text-align: center;
    padding-top: 20%;
    position: relative;
    z-index: 3;
  }

  .description {
    img {
      width: 116px;
    }
  }

  .main-button {
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    width: auto;
  }

  span.main-button {
    width: 135px;
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;

    a {
      color: #fff;
      height: 56px;
      line-height: 56px;
      display: inline-block;
    }
  }

  h1 {
    font-size: 48px;
    color: #fff;
    padding-bottom: 30px;
    position: relative;
    transition: all 2.5s;
  }
  .animate-bottom {
    left: 0;
    right: 0;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-weight: 700;
    position: relative;
    animation: animatebottom 4s linear infinite;
    opacity: 0;

    &:nth-child(2) {
      animation-delay: -2s;
    }
  }

  span {
    font-weight: 700;
  }

  @keyframes animatebottom {
    0% {
      transform: translateY(70px); //jos
      opacity: 0;
      max-width: 0;
    }

    20% {
      transform: translateY(0); //nivel
      opacity: 1;
      max-width: 380px;
    }
    50% {
      transform: translateY(0); //nivel
      opacity: 1;
      max-width: 380px;
    }

    70% {
      transform: translateY(-70px); //sus
      opacity: 0;
      max-width: 0;
    }
    100% {
      transform: translateY(-70px); //sus
      opacity: 0;
      max-width: 0;
    }
  }
}

@media (max-width: 1200px) {
  .hero {
    h1 {
      font-size: 42px;
    }
  }
}

@media (max-width: 991px) {
  .hero {
    height: 700px;

    &::after {
      //display: none;
    }

    h1 {
      font-size: 40px;
    }
  }
}

@media (max-width: 767px) {
  .hero {
    padding-top: 52px;
    margin-top: -52px;
    height: 480px;

    .description {
      img {
        width: auto;
        height: 96px;
      }
    }

    h1 {
      font-size: 32px;
      padding-bottom: 0;
      width: 253px;
    }

    span {
      text-align: center;
      display: block;
    }

    .main-button {
      margin-top: 24px;
    }

    .description {
      padding-top: 134px;
    }
  }
}