@import "../../variables";

.partners-component {
  .custom-hero {
    .description {
      padding-top: 24.5%;
    }

    h1 {
      padding-bottom: 0;
    }
  }

  .steps-section {
    .description {
      padding: 0 20px;
    }

    p {
      font-size: 14px;
      line-height: 20px;
      text-align: left;
    }
  }

  .section-costs {
    padding-bottom: 60px;

    .wrapper-costs {
      background-color: $color-milluu-violet;
      color: #fff;
      padding: 24px;
      border-radius: 16px;
    }

    .section-title {
      line-height: 56px;
      margin-bottom: 16px;
    }

    .description {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 16px;
    }

    .fee {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .content {
      flex: 1;
      max-width: 750px;
      padding-right: 20px;
    }

    .img {
      width: 180px;
    }
  }
}

@media (max-width: 1200px) {
  .partners-component {
    .steps-section p {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

@media (max-width: 991px) {
  .partners-component {
    .steps-section {
      .description {
        padding: 0 5px;
      }
    }

    .section-costs {
      .section-title {
        line-height: 32px;
      }

      .img {
        width: 120px;
      }
    }
  }
}
@media (max-width: 767px) {
  .partners-component {
    .custom-hero {
      .description {
        padding-top: 20%;
      }
    }

    .steps-section {
      p {
        padding-left: 0;
        padding-right: 0;
      }

      .description {
        padding: 0;
      }
    }

    .section-costs {
      padding-bottom: 24px;

      .img {
        width: 180px;
        margin-top: 24px;
      }
    }
  }
}