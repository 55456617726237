@import "../../variables";

.owners-component {
  .table-services {
    position: relative;

    .invisible-row {
      display: flex;
      margin-left: 47%;
      position: absolute;
      width: 53%;
      height: 100%;
      z-index: 2;

      .col-row {
        flex: 1;
        position: relative;
        z-index: 1;
      }
    }

    .row-table,
    .row-head,
    .row-footer {
      display: flex;
      flex-wrap: wrap;

      .col-row {
        flex: 1;
        text-align: center;

        &:first-child {
          text-align: left;
          width: 47%;
          flex: initial;
        }
      }
    }

    &.active-2 {
      .row-head {
        .col-row {
          &:nth-child(2) {
            box-shadow: 0 -3px 0 0 $color-milluu-violet, -3px 0 0 0 $color-milluu-violet, 3px 0 0 0 $color-milluu-violet;
            border-bottom: none;
            border-top-right-radius: 16px;
            border-top-left-radius: 16px;
          }
        }
      }

      .row-table {
        .col-row {
          &:nth-child(2) {
            box-shadow: -3px 0 0 0 $color-milluu-violet, 3px 0 0 0 $color-milluu-violet;
          }
        }
      }

      .row-footer {
        .col-row {
          &:nth-child(2) {
            box-shadow: 3px 0 0 0 $color-milluu-violet, 0 3px 0 0 $color-milluu-violet, -3px 0 0 0 $color-milluu-violet;
            border-bottom-right-radius: 16px;
            border-bottom-left-radius: 16px;
          }
        }
      }
    }

    &.active-3 {
      .row-head {
        .col-row {
          &:nth-child(3) {
            box-shadow: 0 -3px 0 0 $color-milluu-violet, -3px 0 0 0 $color-milluu-violet, 3px 0 0 0 $color-milluu-violet;
            border-top-right-radius: 16px;
            border-top-left-radius: 16px;
          }
        }
      }

      .row-table {
        .col-row {
          &:nth-child(3) {
            box-shadow: -3px 0 0 0 $color-milluu-violet, 3px 0 0 0 $color-milluu-violet;
          }
        }
      }

      .row-footer {
        .col-row {
          &:nth-child(3) {
            box-shadow: 3px 0 0 0 $color-milluu-violet, 0 3px 0 0 $color-milluu-violet, -3px 0 0 0 $color-milluu-violet;
            border-bottom-right-radius: 16px;
            border-bottom-left-radius: 16px;
          }
        }
      }
    }

    .row-head {
      align-items: flex-end;
      border-bottom: 1px solid $gray-1;
      margin-bottom: -1px;

      img {
        margin-bottom: 16px;
      }

      .col-row {
        padding-top: 32px;
        padding-bottom: 16px;
      }
    }

    .row-table {
      &:nth-of-type(even) {
        background: $gray-4;
      }

      .col-row {
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:first-child {
          padding: 10px 8px 10px 16px;
          border-right: 1px solid $gray-1;
          display: block;
        }
      }
    }

    .row-footer {
      z-index: 3;
      position: relative;
      border-top: 1px solid $gray-1;

      .col-row {
        text-align: left;
        padding: 16px 16px 28px 16px;
        margin-top: -1px;
      }

      p {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        margin-bottom: 16px;
      }
    }

    .info-btn {
      line-height: 1;
      margin-left: 8px;
      width: 16px;
      height: 16px;
    }

    p {
      span {
        width: 100%;
      }
    }

    .main-button {
      width: 100%;
    }
  }
}

@media (max-width: 991px) {
  .owners-component {
    .table-services {
      .row-footer {
        p {
          position: relative;
          padding-right: 20px;

          .info-btn {
            position: absolute;
            right: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .owners-component {
    padding-bottom: 48px;

    .wrapper-section-under-hero {
      margin-top: 30px;
    }

    .slider-wrapper {
      padding-bottom: 48px;
    }

    .section-costs {
      padding-bottom: 24px;
    }

    .table-services {
      .invisible-row {
        width: 100%;
        margin-left: 0;
        z-index: 0;

        .col-row {
          margin-top: 77px;
          border-radius: 16px;
        }
      }

      &.active-2 {
        .invisible-row {
          .col-row:nth-child(1) {
            border: 3px solid $color-milluu-violet;
            background: rgba($color-milluu-violet, .05);
          }
        }

        .row-head, .row-table, .row-footer {
          .col-row:nth-child(2) {
            box-shadow: none;
          }
        }
      }

      &.active-3 {
        .invisible-row {
          .col-row:nth-child(2) {
            border: 3px solid $color-milluu-violet;
            background: rgba($color-milluu-violet, .05);
          }
        }

        .row-head, .row-table, .row-footer {
          .col-row:nth-child(3) {
            box-shadow: none;
          }
        }
      }

      .row-table,
      .row-head,
      .row-footer {
        .col-row {
          &:first-child {
            width: 100%;
          }

          /*&:last-child {
            background: rgba($color-milluu-violet, .05);
          }*/
        }
      }

      .row-head {
        img {
          width: 48px;
          margin-bottom: 8px;
        }

        .plan-title {
          font-size: 16px;
          line-height: 24px;

          strong {
            font-weight: 600;
          }
        }

        .col-row {
          padding-top: 16px;
          padding-bottom: 16px;

          &:first-child {
            padding-top: 0;
            padding-bottom: 13px;
            height: 77px;
          }
        }
      }

      .row-table {
        //position: relative;

        /*&::before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 50%;
          transform: translateX(-100%);
          width: 3px;
          height: 100%;
          background: $color-milluu-violet;
          z-index: -1;
        }

        &::after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          width: 3px;
          height: 100%;
          background: $color-milluu-violet;
          z-index: -1;
        }*/

        &:nth-of-type(even) {
          background: transparent;
        }

        .col-row {
          &:first-child {
            border: none;
            border-radius: 8px;
            background-color: #fff;
            padding: 8px;
            box-shadow: $shadow-small-1;
            margin: 0 10px;
            position: relative;

            /*&::after {
              content: '';
              position: absolute;
              right: -10px;
              width: 50%;
              height: 100%;
              top: 0;
              background: rgba($color-milluu-violet, .05);
              z-index: -1;
            }*/

            p {
              position: relative;
              padding-right: 16px;
              font-size: 14px;
              line-height: 24px;
            }

            .info-btn {
              position: absolute;
              right: 0;
              top: 0;
            }
          }

          > img {
            width: 19px;
          }
        }
      }

      .row-footer {
        .col-row {
          padding: 16px;

          &:first-child {
            display: none;
          }
        }
      }
    }
  }
}