@import "../../variables";

.slider-wrapper {
  padding-top: 50px;
  padding-bottom: 86px;
  background: linear-gradient(180deg, $gray-2 0%, rgba(#fff, 0) 100%);

  .section-title {
    margin-bottom: 40px;
  }
}

.slider-testimonials {
  .testimonial {
    .description {
      width: 78.87%;
      padding-right: 40px;

      p {
        max-width: 783px;
      }
    }

    .info-author {
      flex: 1;

      .img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        position: relative;
        margin-bottom: 16px;

        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          margin: auto;
        }
      }

      p {
        font-size: 18px;
        line-height: 24px;
      }

      .category {
        font-size: 14px;
        color: $gray-3;
      }
    }
  }
}

@media (max-width: 991px) {
  .slider-testimonials {
    .testimonial {
      .description {
        width: 70%;
      }
    }
  }
}

@media (max-width: 767px) {
  .slider-wrapper {
    padding-top: 24px;
    padding-bottom: 24px;

    .section-title {
      text-align: center;
      margin-bottom: 24px;
    }
  }

  .slider-testimonials {
    .testimonial {
      .description {
        width: 100%;
        padding-right: 0;
        margin-bottom: 16px;
      }

      .info-author {
        display: flex;
        align-items: center;

        .img {
          margin-bottom: 0;
          margin-right: 16px;
          width: 64px;
          height: 64px;
        }
      }
    }
  }
}