@import "../../variables";

.section-features {
  padding-bottom: 48px;

  .section-features-title {
    display: inline-block;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom-width: 2px;
    border-bottom-style: solid;
  }

  p.section-title {
    margin-bottom: 32px;
  }

  .col-feature {
    margin-bottom: 32px;
  }

  .box-wrapper {
    height: 100%;

    img {
      margin-bottom: 32px;
    }

    .section-title {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 16px;
    }

    p {
      font-size: 14px;
      line-height: 24px;
    }
  }
}

@media (max-width: 767px) {
  .section-features {
    .col-feature {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .box-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      img {
        width: 56px;
        margin-bottom: 0;
        margin-right: 32px;
      }

      .section-title {
        margin-bottom: 0;
        font-size: 16px;
        line-height: 20px;
        flex: 1;
      }

      p {
        width: 100%;
        margin-top: 16px;
      }
    }
  }
}