@import "../../variables";

.tenants-component {
  .section-costs {
    padding-bottom: 80px;
  }

  .table-services {
    margin-top: 32px;

    .box-wrapper {
      padding: 24px;
    }

    .title-table {
      margin-bottom: 26px;

      img {
        margin-right: 16px;
      }
    }

    .option {
      padding-left: 16px;
      margin-bottom: 20px;

      .info-btn {
        line-height: 1;
        margin-left: 8px;
        width: 16px;
        height: 16px;

        img {
          width: 16px;
          height: 16px;
        }
      }

      > img {
        padding-right: 136px;
      }
    }

    .info-wrapper {
      padding-top: 16px;
      margin-top: 16px;
      border-top: 1px solid $gray-1;

      h3 {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 8px;

        strong {
          font-size: 18px;
        }
      }

      p {
        font-size: 14px;
        line-height: 24px;
      }

      .obs {
        font-size: 11px;
        line-height: 14px;
        margin-top: 4px;
      }
    }

    .buttons-group {
      margin-top: 16px;

      & > * {
        flex: 1;
      }
    }
  }
}

@media (max-width: 991px) {
  .tenants-component {
    .table-services {
      .option {
        & > img {
          padding-right: 40px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .tenants-component {
    .section-costs {
      padding-bottom: 24px;

      .section-title {
        text-align: center;
      }
    }

    .table-services {
      margin-top: 16px;

      .title-table {
        margin-bottom: 16px;
      }

      .option {
        padding-left: 5px;

        > img {
          order: 1;
          padding-right: 0;
          margin-right: 14px;
        }

        p {
          order: 2;
          flex: 1;
          position: relative;
          padding-right: 20px;

          .info-btn {
            position: absolute;
            right: 0;
            top: 0;
          }
        }
      }
    }
  }
}