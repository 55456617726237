@import "../../variables";

.steps-section {
  padding-bottom: 80px;

  .section-title {
    margin-bottom: 50px;
  }

  .row {
    margin: 0;
  }

  .col-step {
    padding: 0;
  }

  img {
    width: 140px;
  }

  .step-number {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 16px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 2px;
      margin: auto;
      z-index: 0;
    }

    span {
      width: 56px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 32px;
      border-radius: 50%;
      color: #fff;
      position: relative;
      z-index: 1;
    }
  }

  p {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 8px;
  }

  .link {
    font-size: 14px;
    line-height: 24px;
    color: $color-milluu-blue;
  }

  &.yellow {
    .step-number {
      &::before, span {
        background-color: $color-milluu-yellow;
      }
    }
  }

  &.green {
    .step-number {
      &::before, span {
        background-color: $color-milluu-green;
      }
    }
  }

  &.violet {
    .step-number {
      &::before, span {
        background-color: $color-milluu-violet;
      }
    }
  }

  &.orange {
    .step-number {
      &::before, span {
        background-color: $color-milluu-orange;
      }
    }
  }

  &.blue {
    .step-number {
      &::before, span {
        background-color: $color-milluu-blue;
      }
    }
  }

  &.red {
    .step-number {
      &::before, span {
        background-color: $color-milluu-red;
      }
    }
  }
}

.step-modal {
  &.custom-modal {

  }

  h3 {
    br {
      display: none;
    }
  }

  .main-button {
    margin-top: 16px;
  }
}

@media (min-width: 768px) {
  .steps-section {
    .col-step-2 {
      width: 50%;
    }

    .col-step-3 {
      width: 33.3%;
    }

    .col-step-4 {
      width: 25%;
    }

    .col-step-5 {
      width: 20%;
    }

    .col-step-6 {
      width: 16.6%;
    }
  }
}

@media (max-width: 767px) {
  .steps-section {
    padding-bottom: 48px;

    .section-title {
      text-align: center;
      margin-bottom: 17px;
    }

    .col-step {
      padding-bottom: 24px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 17px;
        height: 100%;
        width: 2px;
        background-color: $color-milluu-yellow;
        margin: auto;
        z-index: 0;
      }
    }

    img {
      order: 2;
      width: 80px;
      height: 80px;
      margin-right: 18px;
    }

    .step-number {
      order: 1;
      width: 32px;
      margin-bottom: 0;
      height: 100%;

      &::before {
        display: none;
      }

      span {
        width: 32px;
        height: 32px;
        font-size: 16px;
      }
    }

    .description {
      order: 3;
      text-align: left;

      br {
        display: none;
      }
    }

    p {
      max-width: 100%;
    }
  }
}