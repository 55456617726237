$color-text: #333333;

$color-milluu-blue: #28A8E0;
$color-milluu-orange: #F15B2A;
$color-milluu-green: #29B473;
$color-milluu-violet: #662D91;
$color-milluu-red: #ED1C24;
$color-milluu-yellow: #FAAF40;

$gray-1: #D9D9D9;
$gray-2: #F2F2F2;
$gray-3: #666;
$gray-4: #F5F5F5;

$shadow-big-1: 0 8px 16px rgba(0, 0, 0, 0.1);
$shadow-big-2: 0 8px 16px rgba(0, 0, 0, 0.2);
$shadow-small-1: 0 2px 4px rgba(0, 0, 0, 0.15);
