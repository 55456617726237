.loader {
  min-height: 100vh;

  svg {
    margin: auto;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 30%;
  }
}