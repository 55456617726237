@import "../../variables";

.what-is-milluu {
  .section-title-wrapper {
    margin-bottom: 40px;

    img {
      display: block;
      margin-bottom: -30px;
      margin-left: -33px;
      position: relative;
      z-index: 1;
    }

    .section-title {
      display: inline-block;
      padding: 1em;
      background-color: #fff;
      border-radius: 16px;
    }
  }

  .description {
    width: 62.67%;
    padding-right: 48px;

    .intro-text {
      max-width: 640px;
      margin-bottom: 32px;
    }

    ul {
      margin-bottom: 32px;

      li {
        font-size: 14px;
        line-height: 24px;
        padding-left: 24px;
        margin-bottom: 8px;
        position: relative;

        &:last-child {
          margin-bottom: 0;
        }

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 4px;
          width: 16px;
          height: 16px;
          background-image: url("./img/icon-check.svg");
        }
      }
    }

    .buttons-group {
      display: flex;

      a {
        margin-right: 19px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .img {
    flex: 1;
  }
}

@media (max-width: 991px) {
  .what-is-milluu {
    .section-title-wrapper {
      text-align: center;

      img {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0;
      }

      .section-title {
        padding: 8px 16px;
      }
    }

    .description {
      width: 100%;
      padding: 0;
      margin-bottom: 24px;
    }

    .img {
      width: 72%;
      margin-left: auto;
      margin-right: auto;
      flex: initial;
      text-align: center;
    }
  }
}

@media (max-width: 767px) {
  .what-is-milluu {
    .section-title-wrapper {
      margin-bottom: 16px;
    }

    .description {
      .intro-text {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 16px;
      }

      ul {
        margin-bottom: 16px;
      }
    }
  }
}