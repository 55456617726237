@import "../../variables";

.footer-component {
  padding-top: 31px;
  padding-bottom: 24px;
  border-top: 1px solid $gray-1;

  .footer-logo-wrapper {
    margin-bottom: 8px;
  }

  .footer-logo {
    width: 89px;
  }

  p, a {
    font-size: 11px;
    line-height: 14px;
  }

  p {
    margin-bottom: 8px;
  }

  a {
    color: $color-text;

    &:hover {
      color: $color-text;
    }
  }

  .terms-menu {
    li {
      position: relative;

      &:not(:last-child)::after {
        content: "|";
        margin-left: 4px;
        margin-right: 4px;
      }
    }
  }

  .socials {
    margin-bottom: 8px;

    li:not(:last-child) {
      margin-right: 8px;
    }
  }

  .links-menu {
    li:not(:last-child) {
      margin-right: 24px;
    }
  }
}

@media (max-width: 991px) {
  .footer-component {
    padding-top: 16px;
    padding-bottom: 16px;

    .footer-logo-wrapper {
      margin-bottom: 16px;
    }

    .socials {
      margin-bottom: 0;
    }

    .links-menu {
      li {
        margin-right: 0;
        margin-bottom: 16px;
      }
    }
  }
;
}